<template>
  <div>
    <Title>
      Анализы
      <router-link to="/analysis/create">
        <plus-button>Создать</plus-button>
      </router-link>
    </Title>

    <analysis-delete v-if="del" :row="row" @close="del = false"/>

    <vue-good-table
        ref="vueTable"
        :columns="columns"
        :isLoading.sync="isLoading"
        :pagination-options="paginationOptions"
        :rows="rows"
        :totalRows="totalRecords"
        mode="remote"
        styleClass="primary-table primary-table--analysis"
        @on-page-change="onPageChange"
        @on-sort-change="onSortChange"
        @on-column-filter="onColumnFilter"
        @on-per-page-change="onPerPageChange"
    >
      <template slot="column-filter" slot-scope="{column, updateFilters }">
        <DatePicker
            v-if="column.filterOptions.field === 'order_date'"
            v-model="serverParams.columnFilters.order_date"
            :isFilter="true"
            :placeholder="column.filterOptions.placeholder"
            @input="updateFilters(column, serverParams.columnFilters.order_date)"
        />
        <DatePicker
            v-else-if="column.filterOptions.field === 'expiry_date'"
            v-model="serverParams.columnFilters.expiry_date"
            :isFilter="true"
            :placeholder="column.filterOptions.placeholder"
            @input="updateFilters(column, serverParams.columnFilters.expiry_date)"
        />
        <DatePicker
            v-else-if="column.filterOptions.field === 'updated_at'"
            v-model="serverParams.columnFilters.updated_at"
            :isFilter="true"
            :placeholder="column.filterOptions.placeholder"
            @input="updateFilters(column, serverParams.columnFilters.updated_at)"
        />
      </template>
      <template slot="table-row" slot-scope="props">
        <div v-if="props.column.field === 'client'">
          {{ getClientFullName(props.row) }}
        </div>
        <div v-else-if="props.column.field === 'user'">
          {{ getUserInfo(props.row) }}
        </div>
        <div v-else-if="props.column.field === 'updated_at'">
          {{ getFieldUpdatedAt(props.row) }}
        </div>
        <div v-else-if="props.column.field === 'status'"
             :style="
              props.row.status.label === 'Отменен'
              ? 'color: #F2323F'
              : props.row.status.label === 'Завершен'
              ? 'color: #46B13A'
              : ''
             "
             class="relative"
        >
          {{ props.row.status.label }}
          <a-dropdown class="primary-table__actions-dropdown">
            <a class="ant-dropdown-link" @click="e => e.preventDefault()">
              <a-icon type="more"/>
            </a>
            <a-menu slot="overlay" class="primary-table__dropdown-menu">
              <a-menu-item v-if="props.row.generated">
                <a @click="viewPdf(props.row.id)">
                  <a-icon type="file-pdf"/>
                  Открыть PDF
                </a>
              </a-menu-item>
              <a-menu-item>
                <a @click="$router.push(`/analysis/${props.row.id}`)">
                  <a-icon type="search"/>
                  Открыть результат
                </a>
              </a-menu-item>
              <a-menu-item>
                <a @click="$router.push(`/analysis/${props.row.id}/edit`)">
                  <a-icon type="edit"/>
                  Редактировать
                </a>
              </a-menu-item>
              <a-menu-item>
                <a @click="onDelete(props.row)">
                  <a-icon type="delete"/>
                  Удалить
                </a>
              </a-menu-item>
            </a-menu>
          </a-dropdown>
        </div>
        <div v-else>
          {{ props.formattedRow[props.column.field] }}
        </div>
      </template>
      <template slot="pagination-bottom" slot-scope="props">
        <Pagination
          :total="props.total"
          :pageChanged="props.pageChanged"
          :perPageChanged="props.perPageChanged"
        />
      </template>
      <div slot="emptystate" class="text-center">
        Нет данных
      </div>
      <template slot="loadingContent">
        <span class="vgt-loading__content">
            Загрузка...
        </span>
      </template>
    </vue-good-table>

  </div>
</template>

<script>
import Vue from 'vue';
import {vueTable} from "@/mixins/vue-table";
import {getAll} from "@/api/analysis/getAll";
import {getPdf} from "@/api/analysis/getPdf";
import PlusButton from "@/components/ui/PlusButton";
import Title from "@/components/ui/Title";
import moment from 'moment';
import DatePicker from "@/components/ui/DatePicker";
import AnalysisDelete from "./Delete";
import ADropdown from 'ant-design-vue/lib/dropdown';
import AMenu from 'ant-design-vue/lib/menu';
import AIcon from 'ant-design-vue/lib/icon';
import Pagination from '@/components/ui/Pagination';

Vue.use(AMenu);

export default {
  components: {
    AnalysisDelete,
    PlusButton,
    Title,
    AIcon,
    ADropdown,
    DatePicker,
    Pagination
  },
  mixins: [vueTable],
  data() {
    return {
      getAll,
    }
  },
  computed: {
    columns() {
      return [
        {
          field: 'id',
          label: 'ID',
          sortable: true,
          width: '7rem',
          tdClass: 'fold',
          filterOptions: {
            enabled: true,
            placeholder: 'ID',
            trigger: 'enter',
            customFilter: true,
            inputType: 'text',
          },
        },
        {
          field: 'client',
          label: 'ФИО',
          sortable: true,
          width: '14.28%',
          tdClass: 'fold',
          filterOptions: {
            enabled: true,
            placeholder: 'ФИО',
            trigger: 'enter',
            customFilter: true,
            inputType: 'text',
          },
        },
        {
          field: 'user',
          label: 'Ответственный',
          sortable: true,
          width: '14.28%',
          tdClass: 'fold',
          filterOptions: {
            enabled: true,
            placeholder: 'Ответственный',
            trigger: 'enter',
            customFilter: true,
            inputType: 'text',
          },
        },
        {
          field: 'order_date',
          label: 'Дата создания',
          sortable: true,
          width: '14.28%',
          tdClass: 'fold',
          type: 'date',
          dateInputFormat: 'dd.MM.yyyy HH:mm',
          dateOutputFormat: 'dd.MM.yyyy HH:mm',
          filterOptions: {
            enabled: true,
            placeholder: 'Дата',
            trigger: 'enter',
            customFilter: true,
            inputType: 'date',
            field: 'order_date',
          },
          formatFn: (value) => {
            return moment(value).format('DD.MM.YYYY HH:mm');
          }
        },
        {
          field: 'expiry_date',
          label: 'Срок окончания',
          sortable: true,
          width: '14.28%',
          tdClass: 'fold',
          type: 'date',
          dateInputFormat: 'dd.MM.yyyy HH:mm',
          dateOutputFormat: 'dd.MM.yyyy HH:mm',
          filterOptions: {
            enabled: true,
            placeholder: 'Дата',
            trigger: 'enter',
            customFilter: true,
            inputType: 'date',
            field: 'expiry_date',
          },
          formatFn: (value) => {
            return moment(value).format('DD.MM.YYYY HH:mm');
          }
        },
        {
          field: 'updated_at',
          label: 'Редактирование',
          sortable: true,
          width: '14.28%',
          tdClass: 'fold',
          type: 'date',
          dateInputFormat: 'dd.MM.yyyy HH:mm',
          dateOutputFormat: 'dd.MM.yyyy HH:mm',
          filterOptions: {
            enabled: true,
            placeholder: 'Дата',
            trigger: 'enter',
            customFilter: true,
            inputType: 'date',
            field: 'updated_at',
          },
          formatFn: (value) => {
            return moment(value).format('DD.MM.YYYY HH:mm');
          }
        },
        {
          field: 'filter_type',
          label: 'Тип',
          sortable: false,
          width: '14.28%',
          tdClass: 'fold',
          filterOptions: {
            enabled: true,
            placeholder: 'Тип',
            trigger: 'enter',
            filterDropdownItems: [
              {value: 'pcr', text: 'ПЦР'},
              {value: 'pcr_ru_en', text: 'ПЦР (ru+en)'},
              {value: 'pcr_ru', text: 'ПЦР (ru)'},
              {value: 'pcr_en', text: 'ПЦР (en)'},
              {value: 'antibody', text: 'Антитела'},
              {value: 'antibody_m_g', text: 'Антитела (m+g)'},
              {value: 'antibody_m', text: 'Антитела (m)'},
              {value: 'antibody_g', text: 'Антитела (g)'},
              {value: 'antibody_gs', text: 'Антитела (g) (капсидный белок)'},
            ],
            customFilter: true,
            inputType: 'select',
          },
        },
        {
          field: 'status',
          label: 'Статус',
          sortable: true,
          width: '14.28%',
          tdClass: 'fold',
          filterOptions: {
            enabled: true,
            placeholder: 'Статус',
            trigger: 'enter',
            filterDropdownItems: [
              {value: 'registered', text: 'Зарегистрирован'},
              {value: 'completed', text: 'Завершен'},
              {value: 'canceled', text: 'Отменен'},
            ],
            customFilter: true,
            inputType: 'select',
          },
        },
      ]
    }
  },
  methods: {
    viewPdf(id) {
      getPdf(id)
    },
    getClientFullName(row) {
      let ru = '';
      let en = '';

      if (row.client.attributes.ru.surname) {
        ru = (row.client.attributes.ru.surname || '') + ' ' +
          (row.client.attributes.ru.name || '') + ' ' +
          (row.client.attributes.ru.patronymic || '');
      }

      if (row.client.attributes.en.surname) {
        en = (row.client.attributes.en.surname || '') + ' ' +
          (row.client.attributes.en.name || '') + ' ' +
          (row.client.attributes.en.patronymic || '');
      }

      return ru ? ru : en;
    },
    getUserInfo(row) {
      return (row.user.surname || '') + ' ' +
        (row.user.name || '') + ' ' +
        (row.user.patronymic || '');
    },
    getFieldUpdatedAt(row) {
      if (row.created_at !== row.updated_at) {
        return moment(row.updated_at).format('DD.MM.YYYY HH:mm');
      }
      return null;
    }
  },
}
</script>