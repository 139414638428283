var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('Title',[_vm._v(" Анализы "),_c('router-link',{attrs:{"to":"/analysis/create"}},[_c('plus-button',[_vm._v("Создать")])],1)],1),(_vm.del)?_c('analysis-delete',{attrs:{"row":_vm.row},on:{"close":function($event){_vm.del = false}}}):_vm._e(),_c('vue-good-table',{ref:"vueTable",attrs:{"columns":_vm.columns,"isLoading":_vm.isLoading,"pagination-options":_vm.paginationOptions,"rows":_vm.rows,"totalRows":_vm.totalRecords,"mode":"remote","styleClass":"primary-table primary-table--analysis"},on:{"update:isLoading":function($event){_vm.isLoading=$event},"update:is-loading":function($event){_vm.isLoading=$event},"on-page-change":_vm.onPageChange,"on-sort-change":_vm.onSortChange,"on-column-filter":_vm.onColumnFilter,"on-per-page-change":_vm.onPerPageChange},scopedSlots:_vm._u([{key:"column-filter",fn:function(ref){
var column = ref.column;
var updateFilters = ref.updateFilters;
return [(column.filterOptions.field === 'order_date')?_c('DatePicker',{attrs:{"isFilter":true,"placeholder":column.filterOptions.placeholder},on:{"input":function($event){return updateFilters(column, _vm.serverParams.columnFilters.order_date)}},model:{value:(_vm.serverParams.columnFilters.order_date),callback:function ($$v) {_vm.$set(_vm.serverParams.columnFilters, "order_date", $$v)},expression:"serverParams.columnFilters.order_date"}}):(column.filterOptions.field === 'expiry_date')?_c('DatePicker',{attrs:{"isFilter":true,"placeholder":column.filterOptions.placeholder},on:{"input":function($event){return updateFilters(column, _vm.serverParams.columnFilters.expiry_date)}},model:{value:(_vm.serverParams.columnFilters.expiry_date),callback:function ($$v) {_vm.$set(_vm.serverParams.columnFilters, "expiry_date", $$v)},expression:"serverParams.columnFilters.expiry_date"}}):(column.filterOptions.field === 'updated_at')?_c('DatePicker',{attrs:{"isFilter":true,"placeholder":column.filterOptions.placeholder},on:{"input":function($event){return updateFilters(column, _vm.serverParams.columnFilters.updated_at)}},model:{value:(_vm.serverParams.columnFilters.updated_at),callback:function ($$v) {_vm.$set(_vm.serverParams.columnFilters, "updated_at", $$v)},expression:"serverParams.columnFilters.updated_at"}}):_vm._e()]}},{key:"table-row",fn:function(props){return [(props.column.field === 'client')?_c('div',[_vm._v(" "+_vm._s(_vm.getClientFullName(props.row))+" ")]):(props.column.field === 'user')?_c('div',[_vm._v(" "+_vm._s(_vm.getUserInfo(props.row))+" ")]):(props.column.field === 'updated_at')?_c('div',[_vm._v(" "+_vm._s(_vm.getFieldUpdatedAt(props.row))+" ")]):(props.column.field === 'status')?_c('div',{staticClass:"relative",style:(props.row.status.label === 'Отменен'
            ? 'color: #F2323F'
            : props.row.status.label === 'Завершен'
            ? 'color: #46B13A'
            : '')},[_vm._v(" "+_vm._s(props.row.status.label)+" "),_c('a-dropdown',{staticClass:"primary-table__actions-dropdown"},[_c('a',{staticClass:"ant-dropdown-link",on:{"click":function (e) { return e.preventDefault(); }}},[_c('a-icon',{attrs:{"type":"more"}})],1),_c('a-menu',{staticClass:"primary-table__dropdown-menu",attrs:{"slot":"overlay"},slot:"overlay"},[(props.row.generated)?_c('a-menu-item',[_c('a',{on:{"click":function($event){return _vm.viewPdf(props.row.id)}}},[_c('a-icon',{attrs:{"type":"file-pdf"}}),_vm._v(" Открыть PDF ")],1)]):_vm._e(),_c('a-menu-item',[_c('a',{on:{"click":function($event){return _vm.$router.push(("/analysis/" + (props.row.id)))}}},[_c('a-icon',{attrs:{"type":"search"}}),_vm._v(" Открыть результат ")],1)]),_c('a-menu-item',[_c('a',{on:{"click":function($event){return _vm.$router.push(("/analysis/" + (props.row.id) + "/edit"))}}},[_c('a-icon',{attrs:{"type":"edit"}}),_vm._v(" Редактировать ")],1)]),_c('a-menu-item',[_c('a',{on:{"click":function($event){return _vm.onDelete(props.row)}}},[_c('a-icon',{attrs:{"type":"delete"}}),_vm._v(" Удалить ")],1)])],1)],1)],1):_c('div',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}},{key:"pagination-bottom",fn:function(props){return [_c('Pagination',{attrs:{"total":props.total,"pageChanged":props.pageChanged,"perPageChanged":props.perPageChanged}})]}}])},[_c('div',{staticClass:"text-center",attrs:{"slot":"emptystate"},slot:"emptystate"},[_vm._v(" Нет данных ")]),_c('template',{slot:"loadingContent"},[_c('span',{staticClass:"vgt-loading__content"},[_vm._v(" Загрузка... ")])])],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }